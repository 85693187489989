import { useAppDispatch, useAppSelector } from "../../store";
import { userLogOut } from "../../store/reducers/usersSlice.store";
import "./NoCustomersPage.scss";
import { UnicargoLogoFull } from "../../assets/images";
import styles from "../../App.module.scss";
import { UiSchemeV2 } from "../../assets/data/ui";
import DecorativeLine from "../../components/ui-decorations/decorative-backgrounds/DecorativeLine";
import HelpButtonDialog from "../../guards/HelpButtonDialog";
import { useLocation } from "react-router-dom";
import { useSendNoCustomersNotifyMailMutation } from "../../store/api-slices/customersSlice.api";
import { useEffect } from "react";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

const NoCustomersPage = () => {
    const userData = useAppSelector((state) => state.rootReducer.users);
    const [trigger, triggerResult] = useSendNoCustomersNotifyMailMutation();
    const location = useLocation();
    const from = location.state?.from || "";

    const dispatch = useAppDispatch();

    useEffect(() => {
        tryTrackEvent("[No Customers Page]: Page viewed");
        const hasVisitedNoCustomersPage = localStorage.getItem("noCustomersPageVisited");
        if (hasVisitedNoCustomersPage === userData.email) return;
        trigger({ email: userData.email });
        localStorage.setItem("noCustomersPageVisited", userData.email);
    }, []);

    const handleLogout = () => {
        dispatch(userLogOut());
    };

    return (
        <>
            <div className="NoCustomersPage">
                <div className="no-customers-header">
                    <UnicargoLogoFull />
                </div>
                <div className="no-customers-title">
                    <span>You're currently not in our system, </span>
                    <span>we'll reach out shortly!</span>
                </div>
                <div className="no-customers-footer">
                    <a target={"_blank"} href="https://www.unicargo.com">
                        Check out our website
                    </a>
                    <span className="logoutContainer">
                        To log out
                        <span className="textButton" onClick={handleLogout}>
                            Click here
                        </span>
                    </span>
                </div>
                <HelpButtonDialog />
            </div>
            <DecorativeLine
                width="100%"
                className={`${styles.LineBackgroundSvg}`}
                colorLeftLine={UiSchemeV2.colors.primaryLight}
                colorRightLine={UiSchemeV2.colors.secondaryGreen}
                colorMiddleCircle={"#05B834"}
                leftLinePercentage={60}
                height="52px"
                borderRadius="0"
                style={{ zIndex: 1 }}
            />
        </>
    );
};

export default NoCustomersPage;
