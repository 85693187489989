import { ArrowRightSquare } from "../../../../assets/icons";
import { useAppSelector } from "../../../../store";
import { useGetCustomersByEmailQuery } from "../../../../store/api-slices/customersSlice.api";
import styles from "./SwitchAccounts.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentCustomer } from "../../../../store/reducers/usersSlice.store";
import { SESSION_STORAGE } from "../../../../assets/data/resources";
import { getStorageItem } from "../../../../helpers/services";
import { registerSuperPropertiesForMixpanel } from "../../../../helpers/services/MixPanelService";
import { ICustomer } from "../../../../models";

export interface ISwitchAccounts {
    onCloseUserActions: () => void;
}

export default function SwitchAccounts({ onCloseUserActions }: ISwitchAccounts) {
    const userData = useAppSelector((state) => state.rootReducer.users);
    const userCustomers = useGetCustomersByEmailQuery(userData.email)?.data?.customers;
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const onSwitchCustomer = (code: string, name: string | undefined = "") => {
        const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
        dispatch(
            setCurrentCustomer({
                ...userData,
                code: code,
                customerName: name,
            }),
        );
        registerSuperPropertiesForMixpanel({
            username: customerData?.name ?? "",
            customerName: name ?? "",
        });
        navigate("/overview");
        onCloseUserActions();
    };

    const renderUserCustomers = () => {
        return userCustomers?.map((userCustomer) => {
            return (
                <li
                    key={userCustomer.code}
                    className={styles.CustomerPreview}
                    onClick={() => onSwitchCustomer(userCustomer.code, userCustomer.name)}
                >
                    {userCustomer.name}
                </li>
            );
        });
    };

    return (
        <div className={styles.SwitchUsersContainer}>
            <button className={styles.BtnSwitchAccount}>
                Switch accounts <ArrowRightSquare />
            </button>

            <div className={styles.Connector}></div>
            <div className={styles.Connector2}></div>

            <ul className={styles.CustomerList}>{renderUserCustomers()}</ul>
        </div>
    );
}
